import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _36083e22 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _07d2c71a = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _2e8e313d = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _6f18057e = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _305d8c3e = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _bcb99d16 = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _24e02373 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _a0ade8e4 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _ac8b5ad2 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _f42f3290 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _b9c2ace8 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _762222f8 = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _1529be61 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _bee347ce = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _1f1c1678 = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _1a91caaa = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _597e4c21 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _24822c1a = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _25c57b7a = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _7380e46a = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _54fcfbb1 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _3ffdf3a5 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _280807a8 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _7e02866e = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _35f4d977 = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _eaf692ae = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _3a74de72 = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _e97949fe = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _245c5138 = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _59ee19d5 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _6a9da658 = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _4121af8c = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _3a71eb4e = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _091cbfd6 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _77fa981e = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _240011ed = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _a16ccf9e = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _d4acb8c2 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _e632adc2 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _0edd846b = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _58457af4 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _2c03aab9 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _65202b7c = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _f5440bf0 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _454ce49c = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _ac9a9458 = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _98f9e6c0 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _dda23db2 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _013f4122 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _75174110 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _2f36fd02 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _633e0035 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _f9be8ccc = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _9f9cd96e = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _777f4d14 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _5c4af5af = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _66f8239a = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _627e697e = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _0df24f51 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _e6e75788 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _642e24f4 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _0fdefe99 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _d5193dec = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _59815ee5 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _71f41f3d = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _07137eee = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _181a0ebf = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _35219ef4 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _7badad7a = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _c761a364 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _6eb64788 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _592465bc = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _45aec1ec = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _36083e22,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _07d2c71a,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _2e8e313d,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _6f18057e,
    name: "calendar"
  }, {
    path: "/customer",
    component: _305d8c3e,
    name: "customer"
  }, {
    path: "/details",
    component: _bcb99d16,
    name: "details"
  }, {
    path: "/goals",
    component: _24e02373,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _a0ade8e4,
    name: "maisoku"
  }, {
    path: "/media",
    component: _ac8b5ad2,
    name: "media"
  }, {
    path: "/privacy",
    component: _f42f3290,
    name: "privacy"
  }, {
    path: "/project",
    component: _b9c2ace8,
    name: "project"
  }, {
    path: "/response",
    component: _762222f8,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _1529be61,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _bee347ce,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _1f1c1678,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _1a91caaa,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _597e4c21,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _24822c1a,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _25c57b7a,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _7380e46a,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _54fcfbb1,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _3ffdf3a5,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _280807a8,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _7e02866e,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _35f4d977,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _eaf692ae,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _3a74de72,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _e97949fe,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _245c5138,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _59ee19d5,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _6a9da658,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _4121af8c,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _3a71eb4e,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _091cbfd6,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _77fa981e,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _240011ed,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _a16ccf9e,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _d4acb8c2,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _e632adc2,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _0edd846b,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _58457af4,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _2c03aab9,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _65202b7c,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _f5440bf0,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _454ce49c,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/registration",
    component: _ac9a9458,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _98f9e6c0,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _dda23db2,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _013f4122,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _75174110,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _2f36fd02,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _633e0035,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _f9be8ccc,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _9f9cd96e,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _777f4d14,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _5c4af5af,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _66f8239a,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _627e697e,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _0df24f51,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _e6e75788,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _642e24f4,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _0fdefe99,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _d5193dec,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _59815ee5,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _71f41f3d,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _07137eee,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _181a0ebf,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _35219ef4,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _7badad7a,
    name: "property-property",
    children: [{
      path: "contact",
      component: _c761a364,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _6eb64788,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _592465bc,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _45aec1ec,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
